import React, { useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const height = 210;
const width = 150;

type AspectRatio = 'portrait' | 'landscape';

const Outer = styled('div')({
  width,
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ImageWrapper = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
});

export const SlideThumbnailImg = styled('img')<{ aspectRatio: AspectRatio }>(({ aspectRatio }) => ({
  display: 'block',
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
  width: aspectRatio === 'landscape' ? '100%' : width,
  height: aspectRatio === 'landscape' ? '100%' : height,
}));

const Thumbnail = ({ src, active }: { src: string; active: boolean }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [aspectRatio, setAspectRatio] = useState<AspectRatio>('portrait');

  const updateImageStyles = () => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const ratio = naturalWidth / naturalHeight;

      if (ratio > 1.05) {
        setAspectRatio('landscape');
      } else {
        setAspectRatio('portrait');
      }
    }
  };

  return (
    <Outer>
      <ImageWrapper
        sx={(theme) => ({
          borderColor: active ? theme.palette.primary.main : theme.palette.grey['300'],
          outline: active ? `1px solid ${theme.palette.primary.main}` : 'none',
        })}
      >
        {!isLoaded && <Skeleton variant="rectangular" width={width} height={height} />}

        <SlideThumbnailImg
          ref={imgRef}
          src={src}
          alt={`slide-${src}`}
          onLoad={() => {
            setIsLoaded(true);
            updateImageStyles();
          }}
          loading="lazy"
          aspectRatio={aspectRatio}
        />
      </ImageWrapper>
    </Outer>
  );
};

export default Thumbnail;
