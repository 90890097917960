import { put } from 'redux-saga/effects';
import { embedPlayerStateChanged } from 'features/content-library/contentLibrarySlice';
import { signalingContentLibraryVideoPlayerStateChanged } from 'features/content-library/actions';

export function* onContentLibraryVideoPlayerRemoteStateChangedSaga(
  action: ReturnType<typeof signalingContentLibraryVideoPlayerStateChanged>
) {
  // @TODO calculate custom time if needed
  const { state, id, currentTime } = action.payload;
  yield put(
    embedPlayerStateChanged(
      {
        id,
        currentTime,
        status: state,
        remoteState: {
          state,
          currentTime,
        },
      },
      {
        remoteInteraction: true,
      }
    )
  );
}
