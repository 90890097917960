import React from 'react';
import { Box, BoxProps, Fade } from '@mui/material';

type TabPanelProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const TabPanel = ({
  children,
  value,
  index,
  sx = [],
  ...props
}: BoxProps<'div', TabPanelProps>) => (
  <Box
    sx={[
      {
        width: '100%',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    role="tabpanel"
    hidden={value !== index}
    {...props}
  >
    <Fade in={value === index} mountOnEnter>
      <div>{children}</div>
    </Fade>
  </Box>
);

export default TabPanel;
