import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { deleteContentLibraryFolderRequested } from 'features/content-library/actions';
import { selectFolder } from 'features/content-library/contentLibrarySlice';
import { useContentLibrary } from 'features/content-library/useContentLibrary';

const DeleteContentLibraryFolderConfirmation = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);
  const folderId = modalData.id as string;

  const folder = useAppSelector((state) => selectFolder(state, folderId));

  const { isFeatureAvailable } = useContentLibrary();

  const shouldClose = !isFeatureAvailable || !folder;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('deleteContentLibraryFolderConfirmation'));
    }
  }, [shouldClose, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('deleteContentLibraryFolderConfirmation'));
  };

  const handleOk = () => {
    if (folder) {
      dispatch(
        deleteContentLibraryFolderRequested({
          id: folderId,
          personal: folder.personal,
        })
      );
      dispatch(closeModal('deleteContentLibraryFolderConfirmation'));
    }
  };

  return (
    <ConfirmationDialog
      open={!shouldClose}
      title={t('content_library.delete_folder.title')}
      description={t('content_library.delete_folder.description')}
      cancelText={t('content_library.delete_folder.btn_cancel_label')}
      okText={t('content_library.delete_folder.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      hideCloseButton
      preventClose
    />
  );
};

export default DeleteContentLibraryFolderConfirmation;
