import { combineReducers } from 'redux';
import applicationReducer from 'features/application/applicationSlice';
import modalReducer from 'features/modal/modalSlice';
import roomReducer from 'features/room/roomSlice';
import layoutReducer from 'features/layout/reducer';
import streamingReducer from 'features/streaming/streamingSlice';
import userMediaReducer from 'features/user-media/userMediaSlice';
import usersReducer from 'features/users/usersSlice';
import toolbarReducer from 'features/toolbar/toolbarSlice';
import devModeReducer from 'features/dev-mode/devModeSlice';
import featuresReducer from 'features/feature-set/featuresSlice';
import brandingReducer from 'features/branding/brandingSlice';
import systemStatsReducer from 'features/system-stats/systemStatsSlice';
import recorderReducer from 'features/recorder/recorderSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import chatReducer from 'features/chat/chatSlice';
import virtualBackgroundReducer from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import permissionsReducer from 'features/permissions/permissionsSlice';
import SDKStateSliceReducer from 'features/sdk/sdkStateSlice';
import e2eeReducer from 'features/e2ee/e2eeSlice';
import whiteboardReducer from 'features/whiteboard/whiteboardSlice';
import breakoutRoomsReducer from 'features/breakout-rooms/breakoutRoomsSlice';
import qaReducer from 'features/qa/qaSlice';
import pollsReducer from 'features/polls/pollsSlice';
import transcriptsReducer from 'features/transcripts/reducer';
import contentLibraryReducer from 'features/content-library/contentLibrarySlice';

const rootReducer = combineReducers({
  application: applicationReducer,
  userMedia: userMediaReducer,
  room: roomReducer,
  layout: layoutReducer,
  streaming: streamingReducer,
  modal: modalReducer,
  users: usersReducer,
  toolbar: toolbarReducer,
  devMode: devModeReducer,
  features: featuresReducer,
  branding: brandingReducer,
  systemStats: systemStatsReducer,
  recorder: recorderReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  transcripts: transcriptsReducer,
  virtualBackground: virtualBackgroundReducer,
  permissions: permissionsReducer,
  e2ee: e2eeReducer,
  SDKState: SDKStateSliceReducer,
  whiteboard: whiteboardReducer,
  breakoutRooms: breakoutRoomsReducer,
  qa: qaReducer,
  polls: pollsReducer,
  contentLibrary: contentLibraryReducer,
});

export default rootReducer;
