import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import { contentLibraryFileOpenRequested } from 'features/content-library/actions';

export function* openContentLibraryFileSaga(
  action: ReturnType<typeof contentLibraryFileOpenRequested>
) {
  const { id, personal } = action.payload;

  const openedFile: ReturnType<typeof selectContentLibraryOpenedFile> = yield select(
    selectContentLibraryOpenedFile
  );

  if (id === openedFile?.id) {
    return;
  }

  yield call(SignalingSocket.send, {
    event: 'openLibraryFile',
    data: {
      personal,
      id,
    },
  });
}
