import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { Slide } from 'features/content-library/components/content-types/styled';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import Player from 'features/content-library/components/content-types/EmbedVideoPlayer/Player';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryVideoPlayer } from 'features/content-library/contentLibrarySlice';
import React from 'react';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export type EmbedVideoProps = {
  file: ContentLibraryOpenedFile;
};

const EmbedVideo = React.memo(({ file }: EmbedVideoProps) => {
  const playerState = useAppSelector((state) => selectContentLibraryVideoPlayer(state, file.id));

  const { hasPermissions } = usePermissions();
  const canPresent = hasPermissions(PermissionTypes.presentContentLibrary);

  return (
    <GridPanelContainer>
      <Slide sx={{ flexGrow: 1 }}>
        <StreamBadge label={file.name} />
        {playerState ? (
          <Player url={file.url} playerState={playerState} canPresent={canPresent} />
        ) : null}
      </Slide>
    </GridPanelContainer>
  );
});

export default EmbedVideo;
