import { takeLatest } from 'redux-saga/effects';
import { virtualBackgroundChangeRequest } from 'features/virtual-backgrounds/actions';
import { changeVirtualBackgroundSaga } from 'features/virtual-backgrounds/sagas/changeVirtualBackgroundSaga';
import { resetVirtualBackgroundSaga } from 'features/virtual-backgrounds/sagas/resetVirtualBackgroundSaga';
import { virtualBackgroundReset } from 'features/virtual-backgrounds/virtualBackgroundsSlice';

export default function* virtualBackgroundWatcher() {
  yield takeLatest(virtualBackgroundChangeRequest, changeVirtualBackgroundSaga);
  yield takeLatest(virtualBackgroundReset, resetVirtualBackgroundSaga);
}
