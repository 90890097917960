import { selectIsBroadcastSetupActive, selectRoomStatus } from 'features/room/roomSlice';
import {
  syncConfigApplied,
  virtualBackgroundReset,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { put, select } from 'redux-saga/effects';

export function* onDisableVBCommandSaga() {
  const roomStatus: string = yield select(selectRoomStatus);
  if (roomStatus !== 'joined') {
    yield put(syncConfigApplied(false));
    return;
  }

  const broadcastSetupActive: boolean = yield select(selectIsBroadcastSetupActive);
  if (broadcastSetupActive) {
    yield put(syncConfigApplied(false));
  } else {
    yield put(virtualBackgroundReset());
  }
}
