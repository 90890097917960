import { fork, select } from 'redux-saga/effects';
import { contentLibraryFileUploadRequested } from 'features/content-library/actions';
import { ContentLibraryManager } from 'utils/content-library/ContentLibraryManager';
import {
  selectActiveFolderId,
  selectActiveLibraryType,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryType } from 'features/content-library/types';

export function* uploadContentLibraryFilesSaga(
  action: ReturnType<typeof contentLibraryFileUploadRequested>
) {
  const activeFolderId: string = yield select(selectActiveFolderId);
  const activeLibraryType: ContentLibraryType = yield select(selectActiveLibraryType);

  yield fork(ContentLibraryManager.upload, action.payload, {
    activeFolderId,
    activeLibraryType,
  });
}
