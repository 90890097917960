import { selectBreakoutTargetRoom } from 'features/breakout-rooms/breakoutRoomsSlice';
import { selectVirtualBackgroundSyncConfig } from 'features/virtual-backgrounds/selectors';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BroadcastSetupContextProvider, useBroadcastSetupMedia } from 'utils/broadcast-setup';
import {
  broadcastSetupEnded,
  selectBroadcastSetupReason,
  selectIsBroadcastSetupInitiator,
} from 'features/room/roomSlice';
import PromptPermissionsModal from 'features/room/broadcast-setup/modals/PromptPermissionsModal';
import SetupFailureModal from 'features/room/broadcast-setup/modals/SetupFailureModal';
import PreviewModal from 'features/room/broadcast-setup/modals/PreviewModal';
import { SubmitHandler } from 'react-hook-form';
import { PreviewFormValues } from 'features/join/publisher/JoinPreview';
import SetupCancelledModal from 'features/room/broadcast-setup/modals/SetupCancelledModal';
import { DialogCloseCallback } from 'components/Dialog';
import { broadcastSetupCancelled, startBroadcastRequest } from 'features/room/actions';

const BroadcastSetup = () => {
  const appDispatch = useAppDispatch();

  const didInitRef = useRef(false);

  const initiator = useAppSelector(selectIsBroadcastSetupInitiator);
  const targetRoom = useAppSelector(selectBreakoutTargetRoom);

  const broadcastSetupReason = useAppSelector(selectBroadcastSetupReason);

  const {
    state: { status, video, audio },
    onAudioInputChange,
    onAudioOutputChange,
    onVideoInputChange,
    onAudioToggle,
    onVideoToggle,
    enableAudio,
    disableAudio,
    enableCamera,
    disableCamera,
    dispatch,
    requestPermissions,
    audioStream,
    videoStream,
    resetVB,
    changeVB,
    changeVBEffect,
    cleanupBroadcastMedia,
  } = useBroadcastSetupMedia();

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;
      requestPermissions();
    }

    return () => {
      cleanupBroadcastMedia();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const syncVBConfig = useAppSelector(selectVirtualBackgroundSyncConfig);

  useEffect(() => {
    changeVBEffect(videoStream, syncVBConfig);
  }, [syncVBConfig, videoStream]);

  const handleClose = () => {
    appDispatch(broadcastSetupEnded());
  };

  const handleCancel: DialogCloseCallback = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    appDispatch(broadcastSetupCancelled());
    dispatch({ type: 'statusUpdated', payload: 'cancelled' });
  };

  const handleStartBroadcast: SubmitHandler<PreviewFormValues> = () => {
    appDispatch(startBroadcastRequest());

    handleClose();
  };

  return (
    <>
      <PromptPermissionsModal
        open={status === 'prompt'}
        reason={broadcastSetupReason}
        username={initiator}
        roomName={targetRoom?.name}
      />
      <SetupFailureModal open={status === 'failure' || status === 'denied'} onClose={handleClose} />
      <BroadcastSetupContextProvider
        value={{
          video,
          audio,
          videoStream,
          audioStream,
          onVideoToggle,
          onAudioToggle,
          onAudioInputChange,
          onAudioOutputChange,
          onVideoInputChange,
          enableAudio,
          disableAudio,
          disableCamera,
          enableCamera,
          dispatch,
          resetVB,
          changeVB,
        }}
      >
        <PreviewModal
          reason={broadcastSetupReason}
          roomName={targetRoom?.name}
          open={status === 'success'}
          onClose={handleCancel}
          startBroadcast={handleStartBroadcast}
        />
      </BroadcastSetupContextProvider>
      <SetupCancelledModal open={status === 'cancelled'} onClose={handleClose} />
    </>
  );
};

export default BroadcastSetup;
