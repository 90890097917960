import loadjs from 'loadjs';
import * as Sentry from '@sentry/react';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';

const customerKey = '12cba3ee81cf4a793796a51b6327c678';

const figmentBundleId = 'figment';

export interface ActivationConfig {
  activeApp: 'VB' | 'AR';
  background: {
    type: string;
    bgImageUrl?: string;
    blurStrength?: string;
  };
  arFilter?: string | object;
  inputFrequency?: number;
  inputMediaStream: {
    mediaStream?: MediaStream;
    videoTrack?: MediaStreamTrack;
    stopTracksOnDestruction: boolean;
  };
  vbQuality?: 'performance' | 'quality';
  maskBehavior?: 'tight' | 'loose';
  showFPS?: boolean;
}

export interface BackgroundOptions {
  type: string;
  value: string;
  stream: MediaStream;
}

export interface InputMediaOptions {
  stream: MediaStream;
  stopTracksOnDestruction?: boolean;
}

export interface SelectedVirtualBackground {
  type: string;
  value: string;
}

export class VirtualBackground {
  private _instance?: any;

  private _activated: boolean = false;

  private _activeBackground: SelectedVirtualBackground = {
    type: '',
    value: '',
  };

  private loadScripts = async () =>
    loadjs(
      [
        `${process.env.PUBLIC_URL}/figment/figment.js`,
        `${process.env.PUBLIC_URL}/figment/vb/tflite/figment-021fg1a9.js`,
        `${process.env.PUBLIC_URL}/figment/vb/tflite/figment-a19j11h7.js`,
      ],
      figmentBundleId,
      { returnPromise: true }
    );

  init = async () => {
    if (!loadjs.isDefined(figmentBundleId)) {
      await this.loadScripts();
      this._instance = new window.FigmentApp({ customerKey });
    }
  };

  get activated() {
    return this._activated;
  }

  get activeBackground() {
    return this._activeBackground;
  }

  activate = async (options: BackgroundOptions): Promise<MediaStream | void> => {
    if (this._activated) {
      return undefined;
    }

    const config: ActivationConfig = {
      activeApp: 'VB',
      background: {
        type: options.type,
      },
      inputFrequency: 1000 / 30,
      vbQuality: 'quality',
      inputMediaStream: {
        mediaStream: options.stream,
        stopTracksOnDestruction: true,
      },
    };

    if (options.type === 'blur') {
      config.background.blurStrength = options.value;
    } else {
      config.background.bgImageUrl = options.value;
    }

    try {
      const stream = await this._instance.activate(config);

      eventBus.sendMessage('virtualBackgroundChanged', { virtualBackground: options });

      this._activated = true;

      this._activeBackground = {
        type: options.type,
        value: options.value,
      };

      return stream;
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ tier: 1 })
        .error('An error occurred while applying virtual background', error);
    }

    return undefined;
  };

  deactivate = async () => {
    if (!this._activated) {
      return;
    }

    try {
      await this._instance.deactivate();
      this._activeBackground = {
        type: '',
        value: '',
      };
    } finally {
      this._activated = false;
    }
  };

  changeBackground = async (config: SelectedVirtualBackground) => {
    try {
      if (config.type === 'blur') {
        await this._instance.setOption('blur_background', config.value);
      } else {
        await this._instance.setOption('set_background_img', config.value);
      }

      eventBus.sendMessage('virtualBackgroundChanged', { virtualBackground: config });

      this._activeBackground = {
        type: config.type,
        value: config.value,
      };
    } catch (error) {
      Sentry.captureException(error);
      logger
        .remote({ tier: 1 })
        .error('An error occurred while applying virtual background', error);

      throw error;
    }
  };

  setInputMedia = async (options: InputMediaOptions) =>
    this._instance.setInputMediaStream(options.stream, options.stopTracksOnDestruction);
}
