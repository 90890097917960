import { selectActiveMediaDevices } from 'features/user-media/userMediaSlice';
import { store } from 'store/store';
import { environment } from 'utils/webrtc/environment';
import { MediaStreamType } from 'features/streaming/types';
import { stopStreamTracks } from 'features/user-media/utils';
import { compoundStream } from 'features/user-media/utils/compoundStream';
import { BasePublishing } from 'utils/webrtc/publishing';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { logger } from 'utils/logger';
import { isValidVBSelection } from 'utils/types';
import { selectVirtualBackgroundSyncConfig } from 'features/virtual-backgrounds/selectors';
import { PublishingOptions } from 'utils/webrtc/types';

interface JoinMedia {
  audio: MediaStream | null;
  video: MediaStream | null;
}

export class PublishingFeed extends BasePublishing {
  joinMedia: JoinMedia = {
    audio: null,
    video: null,
  };

  constructor() {
    super('publishing');
  }

  getActiveMediaConfig = () => {
    const activeMediaDevices = selectActiveMediaDevices(store.getState());

    const videoEnabled = this.mediaStates.video.enabled;
    const audioEnabled = this.mediaStates.audio.enabled;

    return {
      video: videoEnabled
        ? {
            ...environment.videoConstraints,
            deviceId: {
              exact: activeMediaDevices.videoinput,
            },
          }
        : undefined,
      audio: audioEnabled
        ? {
            deviceId: {
              exact: activeMediaDevices.audioinput,
            },
          }
        : undefined,
    };
  };

  setJoinMedia = (stream: MediaStream | null, kind: MediaStreamType) => {
    this.joinMedia[kind] = stream;
  };

  cleanupJoinMedia = (kind?: MediaStreamType) => {
    if (!kind) {
      Object.keys(this.joinMedia).forEach((key) => {
        const media = key as MediaStreamType;

        stopStreamTracks(this.joinMedia[media]);
        this.joinMedia[media] = null;
      });

      return;
    }

    stopStreamTracks(this.joinMedia[kind]);
    this.joinMedia[kind] = null;
  };

  getJoinPublishingOptions = async (): Promise<PublishingOptions> => {
    let stream = compoundStream(this.joinMedia.audio, this.joinMedia.video);
    if (!stream) {
      return {
        video: this.mediaStates.video.enabled,
        audio: this.mediaStates.audio.enabled,
      };
    }

    // This handles scenarios where the join screen is disabled and
    // the virtual background (VB) is configured via the SDK,
    // making it impossible to apply the VB on the join screen
    const syncVBConfig = selectVirtualBackgroundSyncConfig(store.getState());
    if (isValidVBSelection(syncVBConfig) && !this.virtualBackground.activated) {
      stream = await this.applyVirtualBackground(stream, syncVBConfig);
    }

    return {
      video: !!stream.getVideoTracks().length,
      audio: !!stream.getAudioTracks().length,
      stream,
    };
  };

  getResetMediaStates = (preserve: boolean) => {
    if (this.joinMedia?.audio || this.joinMedia?.video) {
      return {
        preserveVideo: !!this.joinMedia.video,
        preserveAudio: !!this.joinMedia.audio,
      };
    }

    if (preserve) {
      return {
        preserveVideo: this.mediaStates.video.enabled,
        preserveAudio: this.mediaStates.audio.broadcasting, // cant use enabled since its always on, because of how we handle audio
      };
    }

    return {
      preserveVideo: this.defaultMediaState.enabled,
      preserveAudio: this.defaultMediaState.enabled,
    };
  };

  private async applyVirtualBackground(
    stream: MediaStream,
    config: SelectedVirtualBackground
  ): Promise<MediaStream> {
    if (stream.getVideoTracks().length > 0) {
      logger.remote().log('Applying the virtual background for the initial publishing');
      try {
        const VBStream = await this.activateVirtualBackground(config, {
          stream,
        });
        if (VBStream) {
          return VBStream;
        }
      } catch (error) {
        logger.remote().debug('Error applying virtual background:', error);
      }
    }
    return stream;
  }
}
