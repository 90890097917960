import { PayloadAction } from '@reduxjs/toolkit';
import { establishPublishingConnectionSaga } from 'features/streaming/sagas/establishPublishingConnectionSaga';
import { PermissionChangePayload, User, UserId } from 'features/users/types';
import { call, put, select } from 'redux-saga/effects';
import { selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { PublishingFeedStatus, RTCClient } from 'utils/webrtc';
import { eventBus } from 'utils/eventBus';
import { selectLocalFeedStatus } from 'features/streaming/streamingSlice';
import { broadcastSetupStarted, selectIsBroadcastSetupActive } from 'features/room/roomSlice';
import { updateOrderSaga } from 'features/layout/sagas/updateOrderSaga';

export function* onBroadcastPermissionGrantedSaga({
  payload,
}: PayloadAction<PermissionChangePayload>) {
  const { initiator, id } = payload;

  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId === id && initiator) {
    RTCClient.publishingFeed.broadcastIntended = true;
    yield call(establishPublishingConnectionSaga, true);

    const isBroadcastSetupActive: boolean = yield select(selectIsBroadcastSetupActive);
    const publishingFeedStatus: PublishingFeedStatus = yield select(selectLocalFeedStatus);

    // Open the broadcast setup if no publishing feed yet. Do nothing otherwise.
    if (publishingFeedStatus === PublishingFeedStatus.idle && !isBroadcastSetupActive) {
      const initiatorUser: User = yield select(selectUserById, initiator.id);
      yield put(broadcastSetupStarted({ initiator: initiatorUser.name }));
    }

    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { broadcast: true });
    return;
  }

  yield call(updateOrderSaga, id);
}
