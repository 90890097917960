import { establishPublishingConnectionSaga } from 'features/streaming/sagas/establishPublishingConnectionSaga';
import { call, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { PermissionChangePayload, User, UserId } from 'features/users/types';
import { selectLocalUserId, selectUserById } from 'features/users/usersSlice';
import { eventBus } from 'utils/eventBus';
import { logger } from 'utils/logger';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';

export function* onScreenshareAllowedSaga({
  payload: { id, initiator },
}: PayloadAction<PermissionChangePayload>) {
  const localUserId: UserId = yield select(selectLocalUserId);
  if (localUserId === id && initiator) {
    logger
      .remote({ tier: 1 })
      .info(`The user was allowed to share screen by user id=${initiator.id}`);

    yield call(establishPublishingConnectionSaga, false);

    const initiatorUser: User = yield select(selectUserById, initiator.id);

    yield call(
      notification,
      i18n.t('notifications:screenshare_allowed', { username: initiatorUser.name })
    );

    yield call(eventBus.sendMessage, 'permissionsChanged', {}, { screenshare: true });
  }
}
