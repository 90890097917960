import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectContentWidth } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { selectPageStreams } from 'features/layout/features/pagination/paginationSlice';
import React, { useContext, useMemo } from 'react';
import { ResizableBox } from 'features/layout/layouts/TiledWithContent/ResizableBox';
import { TileArea } from 'features/layout/pagination/TileArea';
import { useAppSelector } from 'store/hooks';
import { usePaginationObserver } from 'features/layout/usePaginationObserver';
import { Box } from '@mui/material';
import { layoutConfig } from 'utils/layout';
import { Stage } from 'features/layout/Stage/Stage';
import { RoomLayoutValuesContext } from '../../Context';

const RoomGrid = () => {
  usePaginationObserver();
  const maximizedStream = useAppSelector(selectMaximizedStream);
  const contentWidth = useAppSelector(selectContentWidth);
  const { verticalLayout, roomWidth } = useContext(RoomLayoutValuesContext);

  const hasContent = !!maximizedStream;

  const streams = useAppSelector(selectPageStreams);

  const forcedFullscreen = hasContent && !streams.length;

  const verticalFullscreen =
    verticalLayout && ((hasContent && contentWidth === 1) || forcedFullscreen);

  const gridSettings = useMemo(() => {
    if (!hasContent || (forcedFullscreen && !verticalFullscreen))
      return {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
      };

    if (verticalLayout) {
      return verticalFullscreen
        ? {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',
            alignItems: 'center',
          }
        : {
            gridTemplateColumns: `1fr`,
            gridTemplateRows: `${roomWidth * 0.75}px 1fr`,
          };
    }

    return {
      gridTemplateColumns:
        contentWidth === 1
          ? `100% 0px`
          : `${Math.min(
              roomWidth - layoutConfig.minSidebarWidth,
              roomWidth * contentWidth
            )}px  1fr`,
      gridTemplateRows: `1fr`,
    };
  }, [hasContent, contentWidth, roomWidth, verticalLayout, forcedFullscreen, verticalFullscreen]);

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: '1',
        maxHeight: '100%',
        display: 'grid',
        ...gridSettings,
      }}
      data-testid="layout-tiled-content"
    >
      {hasContent ? (
        <ResizableBox>
          <Stage />
        </ResizableBox>
      ) : null}
      <Box
        sx={{
          display: verticalFullscreen ? 'none' : 'flex',
          flexGrow: 1,
          alignItems: 'center',
          overflow: 'hidden'
        }}
      >
        <TileArea />
      </Box>
    </Box>
  );
};

export default RoomGrid;
