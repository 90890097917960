import { selectSignalingInitData } from 'features/application/applicationSlice';
import { SignalingInitData } from 'features/application/types';
import { call, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';

export function* establishPublishingConnectionSaga(intendToPublishMedia: boolean) {
  const initData: SignalingInitData = yield select(selectSignalingInitData);

  const { publishing: publishingMediaServer, subscribing: subscribingMediaServer } =
    initData.mediaServers;

  let replicatedHandle;

  if (publishingMediaServer.url === subscribingMediaServer.url) {
    if (RTCClient.mediaServerConnector.getHandle('base-subscription')?.janus) {
      replicatedHandle = 'base-subscription';
    }
  }

  if (intendToPublishMedia) {
    yield call(
      RTCClient.mediaServerConnector.connectToPublishingServerForMedia,
      'publishing',
      {
        iceServers: initData.iceServers,
        mediaServer: initData.mediaServers.publishing,
      },
      replicatedHandle
    );
  } else {
    yield call(
      RTCClient.mediaServerConnector.connectToPublishingServerForScreenshare,
      'publishing',
      {
        iceServers: initData.iceServers,
        mediaServer: initData.mediaServers.publishing,
      },
      replicatedHandle
    );
  }
}
