import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import SinglePageFile from 'features/content-library/components/content-types/SinglePageFile';
import GalleryFile from 'features/content-library/components/content-types/GalleryFile/GalleryFile';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import EmbedFile from 'features/content-library/components/content-types/EmbedFile';
import EmbedVideo from 'features/content-library/components/content-types/EmbedVideoPlayer/EmbedVideo';

export const ContentPanel = ({ size }: { size: { width?: number; height?: number } }) => {
  const { hasPermissions } = usePermissions();

  const openedFile = useAppSelector(selectContentLibraryOpenedFile);
  if (!openedFile) {
    return null;
  }

  const canPresentFiles = hasPermissions(PermissionTypes.presentContentLibrary);
  if (canPresentFiles && 'pages' in openedFile) {
    return <GalleryFile file={openedFile} size={size} />;
  }

  if (openedFile.type === 'webapp') {
    return <EmbedFile file={openedFile} />;
  }

  if (openedFile.type === 'youtube' || openedFile.type === 'vimeo') {
    return <EmbedVideo file={openedFile} />;
  }

  return <SinglePageFile file={openedFile} />;
};
