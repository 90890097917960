import ReactPlayer from 'react-player';
import { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { ContentLibraryVideoPlayer } from 'features/content-library/types';
import {
  embedPlayerRemoteActionReset,
  embedPlayerStateChanged,
} from 'features/content-library/contentLibrarySlice';
import { useAppDispatch } from 'store/hooks';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';

const Root = styled('div')<{ disableInteraction?: boolean }>(({ disableInteraction }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '.embed-player': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    ...(disableInteraction && {
      cursor: 'default',
      pointerEvents: 'none',
    }),
  },
}));

export type EmbedPlayerProps = {
  url: string;
  playerState: ContentLibraryVideoPlayer;
  canPresent: boolean;
};

const NOCOOKIE_HOST = 'https://www.youtube-nocookie.com';

const Player = ({ url, playerState, canPresent }: EmbedPlayerProps) => {
  const dispatch = useAppDispatch();

  const readyRef = useRef(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  // Workaround for Vimeo player: it starts playing from a seek position but doesn't provide the current time during the initial play event.
  const seekedSecondsRef = useRef(0);

  const { id, status, remoteInteraction, currentTime } = playerState;

  useEffect(() => {
    if (playerRef.current && readyRef.current && remoteInteraction) {
      if (status === 'playing') {
        // console.log('seeking', currentTime);
        playerRef.current.seekTo(currentTime);
      }
    }
  }, [currentTime, remoteInteraction, status]);

  const handlePlay = () => {
    // console.log(
    //   'onPlay',
    //   playerRef.current?.getCurrentTime(),
    //   remoteInteraction ? 'remote' : 'local'
    // );
    if (!remoteInteraction) {
      const time = playerRef.current?.getCurrentTime() ?? seekedSecondsRef.current ?? 0;

      dispatch(
        embedPlayerStateChanged({
          id,
          status: 'playing',
          currentTime: time,
        })
      );
    } else {
      dispatch(embedPlayerRemoteActionReset(id));
    }
  };

  const handlePause = () => {
    // console.log(
    //   'onPause',
    //   playerRef.current?.getCurrentTime(),
    //   remoteInteraction ? 'remote' : 'local'
    // );
    if (!remoteInteraction) {
      dispatch(
        embedPlayerStateChanged({
          id,
          status: 'paused',
          currentTime: playerRef.current?.getCurrentTime() ?? 0,
        })
      );
    } else {
      dispatch(embedPlayerRemoteActionReset(id));
    }
  };

  const handleEnded = () => {
    // console.log('onEnded');

    if (!remoteInteraction) {
      dispatch(
        embedPlayerStateChanged({
          id,
          status: 'ended',
        })
      );
    } else {
      dispatch(embedPlayerRemoteActionReset(id));
    }
  };

  // this event is not available on Youtube
  const handleSeek = (seconds: number) => {
    // console.log('onSeek', seconds);
    seekedSecondsRef.current = seconds;
  };

  const handleReady = () => {
    // console.log('player READY');

    readyRef.current = true;
  };

  const handleError = (error: any) => {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Embed player error', error);
  };

  return (
    <Root disableInteraction={!canPresent}>
      <ReactPlayer
        className="embed-player"
        width="100%"
        height="100%"
        ref={playerRef}
        url={url}
        playsinline
        controls
        playing={status === 'playing'}
        onReady={handleReady}
        onSeek={handleSeek}
        onEnded={handleEnded}
        onPlay={handlePlay}
        onPause={handlePause}
        onError={handleError}
        config={{
          youtube: {
            playerVars: {
              disablekb: 1,
              start: playerState.currentTime,
            },
            embedOptions: {
              host: NOCOOKIE_HOST,
            },
          },
          vimeo: {
            playerOptions: {
              start_time: playerState.currentTime,
            },
          },
        }}
      />
    </Root>
  );
};

export default Player;
