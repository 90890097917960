import { fileClosed } from 'features/content-library/contentLibrarySlice';
import { gridPanelOpened, selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { put, select } from 'redux-saga/effects';

export function* onGridPanelOpenedSaga(action: ReturnType<typeof gridPanelOpened>) {
  const panel = action.payload;
  const currentPanel: GridPanelName = yield select(selectGridPanel);

  if (panel === 'whiteboard' && currentPanel === 'contentLibrary') {
    yield put(fileClosed());
  }

  // TODO: See if whiteboard should be 'stopped' when content library is opened. Does it do something in background?
}
