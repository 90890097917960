import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { embedPlayerStateChanged } from 'features/content-library/contentLibrarySlice';

export function* embedPlayerStateChangeSaga(action: ReturnType<typeof embedPlayerStateChanged>) {
  if (action.meta.remoteInteraction) {
    return;
  }

  yield call(SignalingSocket.send, {
    event: 'changeVideoPlayerState',
    data: {
      id: action.payload.id,
      state: action.payload.status,
      currentTime: action.payload.currentTime,
    },
  });
}
