import { singleton } from 'utils/singleton';
import Uppy, { UploadResult } from '@uppy/core';
import { SignalingSocket } from 'services/signaling';
import {
  ContentLibraryFileUploadAcceptedPayload,
  ContentLibraryType,
} from 'features/content-library/types';
import { notification } from 'features/notifications/toast/notification';
import i18n from 'i18n';
import XHRUpload from '@uppy/xhr-upload';
import { store } from 'store/store';
import {
  fileUploadFailed,
  fileUploadProgressChanged,
} from 'features/content-library/contentLibrarySlice';
import { isError, isUppyRestrictionErrorError } from 'utils/types';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';

interface UploadFileParams {
  activeFolderId: string;
  activeLibraryType: ContentLibraryType;
}

type UploaderMeta = {
  library_id: string;
  expiration: number;
  file_id: string;
  file_name: string;
};

const MAX_FILE_SIZE_IN_MB = 200;
const BYTES_IN_MB = 1024 * 1024;

class Manager {
  private uploader?: Uppy<UploaderMeta>;

  private internalIdByFileId: Record<string, string> = {};

  initialize = () => {
    this.uploader = new Uppy<UploaderMeta>({
      restrictions: {
        maxFileSize: MAX_FILE_SIZE_IN_MB * BYTES_IN_MB,
        allowedFileTypes: ['image/*', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx'],
      },
      locale: {
        pluralize(n) {
          if (n === 1) {
            return 0;
          }
          return 1;
        },
        strings: {
          addedNumFiles: i18n.t('common:file_uploader.addedNumFiles'),
          additionalRestrictionsFailed: i18n.t('common:file_uploader.additionalRestrictionsFailed'),
          exceedsSize: i18n.t('common:file_uploader.exceedsSize'),
          failedToUpload: i18n.t('common:file_uploader.failedToUpload'),
          error: i18n.t('common:file_uploader.failedToUpload'),
          noDuplicates: i18n.t('common:file_uploader.noDuplicates'),
        },
      },
      onBeforeFileAdded: () => true,
    }).use(XHRUpload, {
      endpoint: '',
      formData: true,
      fieldName: 'file',
    });

    this.uploader.on('upload-progress', (uppyFile, progress) => {
      if (progress.bytesUploaded && uppyFile && progress.bytesTotal) {
        const percentage = (progress.bytesUploaded / progress.bytesTotal) * 100;
        store.dispatch(
          fileUploadProgressChanged({
            id: uppyFile.meta.file_id,
            progress: parseFloat(percentage.toFixed(2)),
          })
        );
      }
    });

    return this.uploader;
  };

  upload = async (files: FileList | null, params: UploadFileParams) => {
    if (!files) {
      return;
    }

    const uploadPromises = Array.from(files).map((file) => this.prepareFileUpload(file, params));
    await Promise.allSettled(uploadPromises);

    const uploader = this.getUploader();

    try {
      const result = await uploader.upload();

      if (result) {
        this.handleUploadResults(result);
      }
    } catch (error) {
      Sentry.captureException(error);
      logger.remote().error(`Cannot upload files:`, error);

      notification(i18n.t('notifications:content_library.file_upload_general_error'));
    }

    uploader.clear();
    this.internalIdByFileId = {};
  };

  cancelUpload = (fileId: string) => {
    const internalFileId = this.internalIdByFileId[fileId];
    if (internalFileId) {
      const uploader = this.getUploader();
      uploader.removeFile(internalFileId);
    }
  };

  private getUploader() {
    if (!this.uploader) {
      return this.initialize();
    }

    return this.uploader;
  }

  private prepareFileUpload = async (file: File, params: UploadFileParams) => {
    let fileId = '';

    const uploader = this.getUploader();

    try {
      fileId = uploader.addFile({
        name: file.name,
        type: file.type,
        data: file,
      });

      const { token, id, storageFilename, storageUrl, expirationTimestamp, libraryId } =
        await SignalingSocket.sendAsync<ContentLibraryFileUploadAcceptedPayload>({
          event: 'requestLibraryFileUpload',
          data: {
            personal: params.activeLibraryType === 'personal',
            name: file.name,
            folderId: params.activeFolderId === 'root' ? null : params.activeFolderId,
          },
        });

      this.internalIdByFileId[id] = fileId;

      const xhrUploadPlugin = uploader.getPlugin('XHRUpload');
      if (!xhrUploadPlugin) {
        throw new Error('XHRUpload plugin not available');
      }

      // configure upload endpoint for a specific file
      uploader.setFileState(fileId, {
        xhrUpload: {
          endpoint: storageUrl,
          headers: {
            'Secure-Token': token,
          },
        },
      });

      // set required upload meta
      uploader.setFileMeta(fileId, {
        library_id: libraryId,
        expiration: expirationTimestamp,
        file_id: id,
        file_name: storageFilename,
      });
    } catch (error) {
      uploader.removeFile(fileId);
      delete this.internalIdByFileId[fileId];

      this.handlePrepareFileUploadError(error, file.name);

      throw error;
    }
  };

  private handleUploadResults(result: UploadResult<UploaderMeta, Record<string, any>>) {
    if (result.failed?.length) {
      result.failed.forEach((file) => {
        store.dispatch(
          fileUploadFailed({
            id: file.meta.file_id,
          })
        );
        notification(
          i18n.t('notifications:content_library.file_upload_error', {
            fileName: file.name,
          })
        );
      });
    }
  }

  private handlePrepareFileUploadError(error: unknown, fileName: string) {
    if (isUppyRestrictionErrorError(error)) {
      notification(error.message, { autoClose: 5000 });
    } else {
      Sentry.captureException(error);
      logger.remote().error(`Cannot upload a file=${fileName}`, error);
      if (isError(error)) {
        notification(
          i18n.t('notifications:content_library.file_upload_general_error', {
            fileName,
          }),
          { autoClose: 5000 }
        );
      } else {
        notification(i18n.t('notifications:content_library.file_upload_general_error'));
      }
    }
  }
}

export const ContentLibraryManager = singleton<Manager>(() => new Manager());
