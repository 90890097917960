import { PayloadAction } from '@reduxjs/toolkit';
import { EventBusReceiveMessage } from 'features/application/types';
import { leaveSession } from 'features/room/actions';
import { applyMediaDevicesSaga } from 'features/sdk/applyMediaDevicesSaga';
import { onChangeBrandingOptionsCommandSaga } from 'features/sdk/onChangeBrandingOptionsCommandSaga';
import { onChangeToolbarPositionCommandSaga } from 'features/sdk/onChangeToolbarPositionCommandSaga';
import { onConfigureVBCommandSaga } from 'features/sdk/onConfigureVBCommandSaga';
import { onControlEventListenersCommandSaga } from 'features/sdk/onControlEventListenersCommandSaga';
import { onControlLocalTileCommandSaga } from 'features/sdk/onControlLocalTileCommandSaga';
import { onControlRemoteTileCommandSaga } from 'features/sdk/onControlRemoteTileCommandSaga';
import { onControlTileActionSaga } from 'features/sdk/onControlTileActionSaga';
import { onControlUICallbackSaga } from 'features/sdk/onControlUICallbackSaga';
import { onDisableVBCommandSaga } from 'features/sdk/onDisableVBCommandSaga';
import { onManageBroadcastCommandSaga } from 'features/sdk/onManageBroadcastCommandSaga';
import { onConfigureCaptionsCommandSaga } from 'features/sdk/onConfigureCaptionsCommandSaga';
import { onControlBroadcastCommandSaga } from 'features/sdk/onControlBroadcastCommandSaga';
import { onControlRemoteAudioCommandSaga } from 'features/sdk/onControlRemoteAudioCommandSaga';
import { onControlScreenshareCommandSaga } from 'features/sdk/onControlScreenshareCommandSaga';
import { onEndSessionCommandSaga } from 'features/sdk/onEndSessionCommandSaga';
import { onLowerHandCommandSaga } from 'features/sdk/onLowerHandCommandSaga';
import { onManageScreenshareCommandSaga } from 'features/sdk/onManageScreenshareCommandSaga';
import { onMuteFrameCommandSaga } from 'features/sdk/onMuteFrameCommandSaga';
import { onRaiseHandCommandSaga } from 'features/sdk/onRaiseHandCommandSaga';
import { onRemoveUserCommandSaga } from 'features/sdk/onRemoveUserCommandSaga';
import { onSDKConnectedSaga } from 'features/sdk/onSDKConnectedSaga';
import { onToggleCaptionsCommandSaga } from 'features/sdk/onToggleCaptionsCommandSaga';
import { onToggleRecordingCommandSaga } from 'features/sdk/onToggleRecordingCommandSaga';
import { toolbarVisibilityChanged, toolbarVisibilityToggled } from 'features/toolbar/toolbarSlice';
import { call, put } from 'redux-saga/effects';
import { layoutModeChanged } from 'features/layout/features/config/configSlice';
import { onChangeRoleCommandSaga } from 'features/sdk/onChangeRoleCommandSaga';

export function* eventBusCommandSaga(action: PayloadAction<EventBusReceiveMessage>) {
  const { type, data } = action.payload;

  switch (type) {
    case 'connect':
      yield call(onSDKConnectedSaga, data);
      break;
    case 'enableVideo':
    case 'enableAudio':
    case 'disableVideo':
    case 'disableAudio':
    case 'toggleVideo':
    case 'toggleAudio':
      yield call(onControlBroadcastCommandSaga, type);
      break;
    case 'startScreenshare':
    case 'stopScreenshare':
      yield call(onControlScreenshareCommandSaga, type);
      break;

    case 'startRecording':
      yield call(onToggleRecordingCommandSaga, true);
      break;

    case 'stopRecording':
      yield call(onToggleRecordingCommandSaga, false);
      break;

    case 'showToolbar':
      yield put(toolbarVisibilityChanged(true));
      break;

    case 'hideToolbar':
      yield put(toolbarVisibilityChanged(false));
      break;

    case 'toggleToolbar':
      yield put(toolbarVisibilityToggled());
      break;

    case 'changeLayoutMode':
      yield put(layoutModeChanged(data));
      break;

    case 'leaveSession':
      yield put(leaveSession());

      break;
    case 'endSession':
      yield call(onEndSessionCommandSaga, data);
      break;

    case 'requestToggleAudio':
      yield call(onControlRemoteAudioCommandSaga, undefined, data);
      break;

    case 'requestMute':
      yield call(onControlRemoteAudioCommandSaga, true, data);
      break;

    case 'requestUnmute':
      yield call(onControlRemoteAudioCommandSaga, false, data);
      break;

    case 'removeUser':
      yield call(onRemoveUserCommandSaga, data);
      break;

    case 'toggleCaptions':
      yield call(onToggleCaptionsCommandSaga, undefined);
      break;

    case 'showCaptions':
      yield call(onToggleCaptionsCommandSaga, true);
      break;

    case 'hideCaptions':
      yield call(onToggleCaptionsCommandSaga, false);
      break;

    case 'configureCaptions':
      yield call(onConfigureCaptionsCommandSaga, data);
      break;

    case 'raiseHand':
      yield call(onRaiseHandCommandSaga);
      break;

    case 'lowerHand':
      yield call(onLowerHandCommandSaga, data);
      break;

    case 'allowBroadcast':
      yield call(onManageBroadcastCommandSaga, data, true);
      break;

    case 'disallowBroadcast':
      yield call(onManageBroadcastCommandSaga, data, false);
      break;

    case 'allowScreenshare':
      yield call(onManageScreenshareCommandSaga, data, true);
      break;

    case 'disallowScreenshare':
      yield call(onManageScreenshareCommandSaga, data, false);
      break;

    case 'configureVirtualBackground':
      yield call(onConfigureVBCommandSaga, data, true);
      break;

    case 'disableVirtualBackground':
      yield call(onDisableVBCommandSaga);
      break;

    case 'muteFrame':
    case 'unmuteFrame':
    case 'toggleMuteFrame':
      yield call(onMuteFrameCommandSaga, type);
      break;

    case 'changeToolbarPosition':
      yield call(onChangeToolbarPositionCommandSaga, data);
      break;

    case 'changeBrandingOptions':
      yield call(onChangeBrandingOptionsCommandSaga, data);
      break;

    case 'minimizeLocalTile':
    case 'maximizeLocalTile':
      yield call(onControlLocalTileCommandSaga, type);
      break;

    case 'pinUser':
    case 'maximizeUser':
    case 'minimizeContent':
      yield call(onControlRemoteTileCommandSaga, type, data);
      break;

    case 'connectEventListener':
    case 'disconnectEventListener':
      yield call(onControlEventListenersCommandSaga, type, data);
      break;

    case 'connectUICallback':
    case 'disconnectUICallback':
      yield call(onControlUICallbackSaga, type, data);
      break;

    case 'changeRole':
      yield call(onChangeRoleCommandSaga, data);
      break;

    case 'addTileAction':
    case 'removeTileAction':
      yield call(onControlTileActionSaga, type, data);
      break;

    case 'applyMediaDevices':
      yield call(applyMediaDevicesSaga, data);
      break;

    default:
      break;
  }
}
