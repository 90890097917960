import { RootState } from 'store/store';
import { createSelector } from '@reduxjs/toolkit';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { selectDeviceType } from 'features/application/applicationSlice';

export const selectVirtualBackgroundStatus = (state: RootState) => state.virtualBackground.status;
export const selectActiveVirtualBackground = (state: RootState) => state.virtualBackground.selected;
export const selectActiveVirtualInitialized = (state: RootState) =>
  state.virtualBackground.initialized;
export const selectCustomVirtualBackgroundUrl = (state: RootState) =>
  state.virtualBackground.customImageUrl;
export const selectVirtualBackgroundEnforced = (state: RootState) =>
  state.virtualBackground.enforce;
export const selectVirtualBackgroundSyncConfig = (state: RootState) =>
  state.virtualBackground.syncConfig;

export const selectVirtualBackgroundAvailable = createSelector(
  [
    (state) => selectFeatureFlag(state, 'virtualBackgrounds'),
    selectVirtualBackgroundEnforced,
    selectDeviceType,
  ],
  (enabled, enforced, deviceType) => deviceType === 'desktop' && enabled && !enforced
);
