import { createAction } from '@reduxjs/toolkit';
import {
  ChangeRolePayload,
  PermissionChangePayload,
  RoleChangedPayload,
  UserId,
} from 'features/users/types';

export const allowBroadcast = createAction<UserId>('permissions/allowBroadcast');
export const disallowBroadcast = createAction<UserId>('permissions/disallowBroadcast');
export const signalingBroadcastAllowed = createAction<PermissionChangePayload>(
  'signaling/broadcastAllowed'
);
export const signalingBroadcastDisallowed = createAction<PermissionChangePayload>(
  'signaling/broadcastDisallowed'
);

export const allowScreenshare = createAction<UserId>('permissions/allowScreenshare');
export const disallowScreenshare = createAction<UserId>('permissions/disallowScreenshare');
export const signalingScreenshareAllowed = createAction<PermissionChangePayload>(
  'signaling/screenshareAllowed'
);
export const signalingScreenshareDisallowed = createAction<PermissionChangePayload>(
  'signaling/screenshareDisallowed'
);

export const changeRoleRequest = createAction<ChangeRolePayload>('permissions/changeRoleRequest');
export const signalingRoleChanged = createAction<RoleChangedPayload>('signaling/roleChanged');

export const broadcastPermissionGranted = createAction<PermissionChangePayload>(
  'permissions/broadcastPermissionGranted'
);
export const broadcastPermissionRevoked = createAction<PermissionChangePayload>(
  'permissions/broadcastPermissionRevoked'
);

export const whiteboardPermissionGranted = createAction<PermissionChangePayload>(
  'permissions/whiteboardPermissionGranted'
);

export const whiteboardPermissionRevoked = createAction<PermissionChangePayload>(
  'permissions/whiteboardPermissionRevoked'
);

export const allowEditWhiteboard = createAction<UserId>('permissions/allowEditWhiteboard');
export const disallowEditWhiteboard = createAction<UserId>('permissions/disallowEditWhiteboard');

export const signalingEditWhiteboardAllowed = createAction<PermissionChangePayload>(
  'signaling/editWhiteboardAllowed'
);
export const signalingEditWhiteboardDisallowed = createAction<PermissionChangePayload>(
  'signaling/editWhiteboardDisallowed'
);
